<template>
  <div class="page_wrap">
    <top></top>
    <div class="home fs30" id="target_top">
      <div class="header flex bg-ff align-center justify-center"></div>
      <!-- 顶部end -->
      <!-- 主体内容 start -->
      <div class="content">
        <div class="deadtit">确认订单信息</div>
        <div class="flex headtit">
          <div class="spxx">商品信息</div>
          <div class="item">单价</div>
          <div class="item">数量</div>
          <div class="item">优惠</div>
          <div class="item">小计</div>
        </div>
        <div class="shopxx flex">
          <div class="flex-3 flex">
            <div>
              <img class="block-86" :src="orderinfo.goods.thumb" alt="" />
            </div>
            <div style="margin-left: 26px">
              <div class="shopname">{{ orderinfo.goods.name }}</div>
              <div class="shopsku">{{ orderinfo.goods.key_name }}</div>
            </div>
          </div>
          <div class="flex-1">
            <img
              src="../../assets/image/pt.png"
              style="margin-right: 5px"
              alt=""
            />
            <span class="item">{{ orderinfo.goods.shop_price }}</span>
          </div>
          <div class="flex-1 item">{{ orderinfo.goods.number }}</div>
          <div class="flex-1 item">{{}}</div>
          <div class="flex-1 item">{{ orderinfo.goods.goods_money }}</div>
        </div>
        <div class="lybox">
          <div>
            留言:
            <el-input
              class="lyipt"
              v-model="lystr"
              placeholder="选填:填写内容已和卖家协商确认"
            ></el-input>
          </div>
          <div class="pricebox">
            <span>{{ orderinfo.goods.number }}件商品 商品金额：</span>
            <span class="price"
              ><span style="font-size: 12px">￥</span
              >{{ orderinfo.payable_money }}</span
            >
          </div>
        </div>
        <div class="flex footer">
          <div class="rightox">
            <div class="rbox">
              <span>应付金额：</span>
              <span class="price"
                ><span style="font-size: 12px">￥</span
                >{{ orderinfo.payable_money }}</span
              >
            </div>
          </div>
        </div>
        <div class="flex footer">
          <div>
            <el-button class="tjorder" @click="submitorder">提交订单</el-button>
          </div>
        </div>
      </div>
      <!-- 主体内容 end -->
      <div
        class="mask"
        @click="dialogTableVisible = false"
        v-if="dialogTableVisible"
      ></div>
      <div
        class="box-content bg-white radius-3 radius-10"
        v-if="dialogTableVisible"
      >
        <div class="" style="position: relative">
          <router-link to="/publish">
            <div
              class="flex align-center padding-tb-20 padding-lr-30 solid-bottom point"
            >
              <img src="../../assets/image/home/add1.png" class="block-24" />
              <span class="padding-left-10 fs14">动态</span>
            </div>
          </router-link>
          <div
            class="flex align-center padding-tb-20 padding-lr-30 solid-bottom point"
            @click="toPublish(1, '/editArticle')"
          >
            <img src="../../assets/image/home/add2.png" class="block-24" />
            <span class="padding-left-10 fs14">文章（作家权限）</span>
          </div>
          <div
            class="flex align-center padding-tb-20 padding-lr-30 point"
            @click="toPublish(2, '/editBook')"
          >
            <img src="../../assets/image/home/add3.png" class="block-24" />
            <span class="padding-left-10 fs14">电子书（作家权限）</span>
          </div>
          <div class="bg-f6f7 height10 w270"></div>
        </div>
        <div
          class="padding-tb-15 text-center fs14 text-e54f"
          @click="dialogTableVisible = false"
        >
          取消
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import dynamic from "@/components/dynamic/dynamic";
import bookList from "@/components/book-list/book-list";
import top from "@/components/top";
import bottom from "@/components/bottom";
import loadMore from "@/components/load-more/load-more.vue";
export default {
  components: {
    top,
    bottom,
    dynamic,
    bookList,
    loadMore,
  },
  name: "Home",
  data() {
    return {
      mianimg: "",
      isInit: false,
      page: 1,
      goods_id: "",
      totalSize: 0, //总页数
      loading: false,
      orderinfo: {},
      lystr: "",
      iscompleted: false, //当设置为 true 时将不会再触发 infinite-scroll 事件.当所有数据全部加载完毕后，建议设置为 true
      scrollTop: 0,
      dialogTableVisible: false, //新建弹框
      lists: [],
      group_id: "",
    };
  },
  computed: {
    // 是否登录状态
    isLogin() {
      return this.$store.getters.isLogin;
    },
    // 是否是作家
    is_writer() {
      return this.$store.getters.is_writer;
    },
  },
  mounted() {
    this.goods_id = this.$route.query.goods_id;
    this.sku_id = this.$route.query.sku_id;
    this.number = this.$route.query.number;
    this.group_id = this.$route.query.group_id;
    window.addEventListener("scroll", this.handleScroll);
    this.loadData();
    window["getSonData"] = () => {
      this.loadData();
    };
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    /* 通过code获取openid */
    codeGetOpenid(code) {
      this.ajax(
        "post",
        "/v1/619714950d880",
        {
          code: code,
        },
        (res) => {
          if (res.code == 1) {
            localStorage.setItem(
              "USER_INFO",
              JSON.stringify(res.data.userinfo)
            );
            localStorage.setItem("user_token", res.data.userinfo.user_token);
            this.$store.commit("updateUserInfo", res.data.userinfo);
            // window.location.reload();  //刷新页面
          } else if (res.code == -999 && res.data.result) {
            this.$router.push({
              path: "/bind_phone",
              query: {
                openid: res.data.openid,
                unionid: res.data.result.unionid,
                nickName: res.data.result.nickname,
                avatarUrl: res.data.result.headimgurl,
              },
            });
          }
        },
        (err) => {
          console.log("微信登录code获取失败", err);
        }
      );
    },
    handleChange(value) {
      console.log(value);
    },
    // 去发布
    async toPublish(type, path) {
      let auditInfo = await this.getAuditInfo();
      // 0  审核中
      // 1  审核通过
      // 2  审核拒绝
      // 3  待支付
      let info = type == 1 ? "文章" : "电子书";
      switch (auditInfo.status) {
        case -99:
          this.$confirm(`只有作家才可以发布${info}哦，去认证?`, "提示", {
            confirmButtonText: "好的",
            type: "info",
          })
            .then(() => {
              this.$router.push("/authen");
            })
            .catch(() => {});
          break;
        case 0:
          this.$confirm("认证审核中，请耐心等待", "提示", {
            confirmButtonText: "好的",
            type: "info",
          })
            .then(() => {})
            .catch(() => {});
          break;
        case 1:
          this.$router.push({
            path: path,
            query: {
              ducoment_type: 2,
            },
          });
          break;
        case 2:
          let reasons = auditInfo.reason || "暂无原因";
          this.$confirm(
            "认证审核未通过，拒绝原因为：" + reasons + "，请重新提交",
            "提示",
            {
              confirmButtonText: "好的",
              cancelButtonText: "不了",
              type: "info",
            }
          )
            .then(() => {
              this.$router.push("/authen");
            })
            .catch(() => {});
          break;
        case 3:
          this.$confirm("认证未支付，去支付？", "提示", {
            confirmButtonText: "好的",
            cancelButtonText: "不了",
            type: "info",
          })
            .then(() => {
              this.addOrder(auditInfo.period_id, auditInfo.id);
            })
            .catch(() => {});
          break;
        default:
          break;
      }
    },
    // 获取认证信息
    getAuditInfo() {
      return new Promise((resolve, reject) => {
        this.ajax("post", "/v1/6142e36210b86", {}, (res) => {
          if (res.code == -201) {
            this.$router.push("/login");
          } else {
            resolve(res.data);
          }
        });
      });
    },
    // 统一下单接口
    addOrder(period_id, certified_id) {
      this.ajax(
        "post",
        "/v1/5d784b976769e",
        {
          order_type: 4, //1 ：'现金充值'，2：'虚拟币充值'，3 ：'商城交易'，4：认证支付5：打赏6：文章购买
          period_id: period_id,
          certified_id: certified_id,
        },
        (res) => {
          if (res.code == 1) {
            let order_sn = res.data.order_sn;
            this.$router.push({
              path: "/payMoney",
              query: {
                order_sn: res.data.order_sn,
                order_money: res.data.money,
                return_url: JSON.stringify(window.location.href),
              },
            });
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },
    // 提交订单
    submitorder() {
      this.ajax(
        "post",
        "/v1/5d784b976769e",
        {
          order_type: 7, //1 ：'现金充值'，2：'虚拟币充值'，3 ：'商城交易'，4：认证支付5：打赏6：文章购买
          order_money: this.orderinfo.payable_money,
          payable_money: this.orderinfo.payable_money,
          order_info: JSON.stringify({ goods: this.orderinfo.goods }),
          group_id: this.group_id,
        },
        (res) => {
          if (res.code == 1) {
            let order_sn = res.data.order_sn;
            this.$router.push({
              path: "/payMoney",
              query: {
                order_sn: res.data.order_sn,
                order_money: res.data.money
                  ? res.data.money
                  : res.data.payable_money,
                return_url: JSON.stringify(window.location.href),
                isconfirm: 1,
              },
            });
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },
    handleScroll(e) {
      this.scrollTop =
        e.target.documentElement.scrollTop || e.target.body.scrollTop; // 执行代码
    },
    // 发布动态
    publish() {
      this.$router.push("/publishDynac");
    },
    getNews() {
      this.ajax(
        "post",
        "/common/newsList",
        {
          cate_id: this.calatId,
        },
        (res) => {
          if (res.code == 1) {
            this.newsList = res.data;
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },
    // 显示按钮的显示与隐藏
    scrollToTop() {
      let scrollToptimer = setInterval(function () {
        // console.log("定时循环回到顶部");
        var top = document.body.scrollTop || document.documentElement.scrollTop;
        var speed = top / 4;
        document.documentElement.scrollTop -= speed;
        if (top == 0) {
          clearInterval(scrollToptimer);
        }
      }, 30);
    },
    // 推荐数据加载
    loadData() {
      this.ajax(
        "post",
        "/v1/6380c3880e917",
        {
          goods_id: this.goods_id,
          sku_id: this.$route.query.sku_id,
          number: this.$route.query.number,
        },
        (res) => {
          this.isInit = true;
          if (res.code == 1) {
            this.orderinfo = res.data;
          } else {
            this.$message.error(res.msg);
          }
        },
        (err) => {
          console.log(err);
          this.hadGotCode = false;
        }
      );
    },
    changeimg(index) {
      this.active = index;
      this.mianimg = this.goodsinfo.images[index];
    },
    tospellpage(item) {
      this.ptdata = item;
      this.qpyVisible = true;
    },
  },
};
</script>
<style >
.jsq .el-input__inner {
  width: 40px;
  height: 36px;
  line-height: 36px;
}
</style>
<style lang="scss" scoped="scoped">
// @import url("./home.css");
.loading {
  -webkit-animation: rotation 0.4s linear;
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(180deg);
  }
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
/* 右侧信息 */
.box-content {
  position: fixed;
  width: 270px;
  right: 315px;
  bottom: 93px;
  z-index: 1510;
  background-color: #ffffff;
}
.float-box {
  position: fixed;
  right: 245px;
  bottom: 138px;
  width: 72px;
  height: 246px;
  background: #ffffff;
  box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.08);
  opacity: 1;
  border-radius: 43px;
}
.content {
  margin-top: 113px;
  margin-left: 19%;
  width: 62%;
  border: 1px solid #eee;
  padding: 32px 40px;
  .deadtit {
    font-size: 14px;
    color: #333;
    font-weight: bold;
  }
  .headtit {
    padding: 22px 32px;
  }
  .spxx {
    flex: 3;
    color: #333;
    font-size: 14px;
  }
  .item {
    flex: 1;
    text-align: center;
    font-size: 14px;
    color: #333;
  }
  .shopxx {
    border: 1px solid #e2e2e2;
    padding: 17px 32px;
    .shopname {
      font-size: 14px;
      color: #333;
    }
    .shopsku {
      font-size: 14px;
      color: #999;
      margin-top: 33px;
    }
  }
}
.lybox {
  height: 106px;
  background: rgba(229, 79, 66, 0.19);
  padding: 32px;
  font-size: 14px;
  .lyipt {
    width: 500px;
    height: 36px;
    background: #fff;
    margin-left: 16px;
  }
}
.pricebox {
  margin-top: 33px;
  text-align: right;
  .price {
    color: #fd4d39;
    font-size: 24px;
  }
}
.footer {
  justify-content: end;
  .rightox {
    width: 460px;
    height: 77px;
    background: rgba(229, 79, 66, 0.08);
    border: 1px solid #e54f42;
    margin-top: 20px;
    .rbox {
      padding: 0 14px;
      height: 61px;
      margin: 8px;
      font-size: 14px;
      line-height: 61px;
      background: #fff;
      text-align: right;
      .price {
        color: #fd4d39;
        font-size: 24px;
      }
    }
  }
  .tjorder {
    width: 155px;
    height: 46px;
    background: #e54f42;
    color: #fff;
  }
}
.bg-ff {
  background: #fff;
}
.fs-12 {
  font-size: 12px;
}
.fs-20 {
  font-size: 20px;
}
.flex-3 {
  flex: 3;
}
.flex-1 {
  flex: 1;
  line-height: 86px;
}
</style>
